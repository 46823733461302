import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyEarningRule, Status } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';

import { LoadDataResult } from './loadData';
import { getAppInstallStatus } from './getAppInstallStatus';

export async function getDemoContent(
  flowAPI: ControllerFlowAPI,
  loyaltyProgram: LoyaltyProgram,
): Promise<LoadDataResult> {
  const { translations, controllerConfig } = flowAPI;
  const { t } = translations;
  const { wixCodeApi } = controllerConfig;

  const {
    isStoresAppInstalled,
    isEventsAppInstalled,
    isBookingsAppInstalled,
    isPricingPlansInstalled,
    isOldRestaurantsAppInstalled,
    isNewRestaurantsAppInstalled,
  } = await getAppInstallStatus(wixCodeApi);

  const showFlexibleReward =
    isStoresAppInstalled || isBookingsAppInstalled || isOldRestaurantsAppInstalled || isNewRestaurantsAppInstalled;

  const showFixedReward =
    isStoresAppInstalled ||
    isEventsAppInstalled ||
    isBookingsAppInstalled ||
    isPricingPlansInstalled ||
    isNewRestaurantsAppInstalled ||
    !showFlexibleReward; // To show at least one demo reward when no other supported app is installed

  const earningRules: LoyaltyEarningRule[] = [];
  const rewards: Reward[] = [];

  loyaltyProgram.status = ProgramStatus.ACTIVE;

  if (isOldRestaurantsAppInstalled || isNewRestaurantsAppInstalled) {
    earningRules.push({
      conversionRate: {
        configs: [
          {
            moneyAmount: 1,
            points: 1,
          },
        ],
      },
      status: Status.ACTIVE,
      title: t('app.fixtures.earning-rule-a.title'),
      triggerActivityType: 'wix_restaurants_orders-order_submitted',
    });
  }

  earningRules.push({
    fixedAmount: {
      configs: [
        {
          points: 50,
        },
      ],
    },
    status: Status.ACTIVE,
    title: t('app.fixtures.earning-rule-b.title'),
    triggerActivityType: 'wix_members-uou_signs_up',
  });

  if (showFlexibleReward) {
    rewards.push({
      active: true,
      discountAmount: {
        configsByTier: [
          {
            amount: '1',
            costInPoints: 10,
          },
        ],
      },
      name: t('app.fixtures.reward-a.title'),
      type: RewardType.DISCOUNT_AMOUNT,
    });
  }

  if (showFixedReward) {
    rewards.push({
      active: true,
      couponReward: {
        percentage: {
          configsByTier: [
            {
              percentage: 25,
              costInPoints: 15,
            },
          ],
        },
      },
      name: t('app.fixtures.reward-b.title'),
      type: RewardType.COUPON_REWARD,
    });
  }

  return {
    earningRules,
    rewards,
    tiersProgramSettings: {},
    tiers: [],
    loyaltyProgram,
  };
}
