import { IWixAPI } from '@wix/yoshi-flow-editor';
import {
  WIX_STORES as STORES_APP_DEF_ID,
  WIX_EVENTS as EVENTS_APP_DEF_ID,
  WIX_BOOKINGS as BOOKINGS_APP_DEF_ID,
  PRICING_PLANS as PRICING_PLANS_APP_DEF_ID,
  WIX_RESTAURANTS_ORDERS as OLD_RESTAURANTS_APP_DEF_ID,
  WIX_RESTAURANTS_ORDERS_NEW as NEW_RESTAURANTS_APP_DEF_ID,
} from '@wix/app-definition-ids';

const STORES_SECTION_ID = 'product_page';
const EVENTS_SECTION_ID = 'events';
const BOOKINGS_SECTION_ID = 'bookings_list';
const PRICING_PLANS_SECTION_ID = 'membership_plan_picker_tpa';
const OLD_RESTAURANTS_SECTION_ID = 'online_ordering';
const NEW_RESTAURANTS_SECTION_ID = 'menu-orders-page-48605ac5-53fc-49b4-96b2-87f7636f5ce0';

interface AppInstallStatus {
  isStoresAppInstalled: boolean;
  isEventsAppInstalled: boolean;
  isBookingsAppInstalled: boolean;
  isPricingPlansInstalled: boolean;
  isOldRestaurantsAppInstalled: boolean;
  isNewRestaurantsAppInstalled: boolean;
}

export async function getAppInstallStatus(wixCodeApi: IWixAPI): Promise<AppInstallStatus> {
  const isAppSectionInstalled = async (appDefinitionId: string, sectionId: string) =>
    wixCodeApi.site.isAppSectionInstalled({ appDefinitionId, sectionId });

  const [
    isStoresAppInstalled,
    isEventsAppInstalled,
    isBookingsAppInstalled,
    isPricingPlansInstalled,
    isOldRestaurantsAppInstalled,
    isNewRestaurantsAppInstalled,
  ] = await Promise.all([
    isAppSectionInstalled(STORES_APP_DEF_ID, STORES_SECTION_ID),
    isAppSectionInstalled(EVENTS_APP_DEF_ID, EVENTS_SECTION_ID),
    isAppSectionInstalled(BOOKINGS_APP_DEF_ID, BOOKINGS_SECTION_ID),
    isAppSectionInstalled(PRICING_PLANS_APP_DEF_ID, PRICING_PLANS_SECTION_ID),
    isAppSectionInstalled(OLD_RESTAURANTS_APP_DEF_ID, OLD_RESTAURANTS_SECTION_ID),
    isAppSectionInstalled(NEW_RESTAURANTS_APP_DEF_ID, NEW_RESTAURANTS_SECTION_ID),
  ]);

  return {
    isStoresAppInstalled,
    isEventsAppInstalled,
    isBookingsAppInstalled,
    isPricingPlansInstalled,
    isOldRestaurantsAppInstalled,
    isNewRestaurantsAppInstalled,
  };
}
